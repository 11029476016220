<template>
  <div
    class="field-table flex-1 flex flex-col max-h-full max-w-full min-h-full text-base-content"
  >
    <sub-header></sub-header>
    <div class="flex relative flex-1 flex-col flex-grow max-h-full max-w-full">
      <vue-good-table
        :isLoading="loading"
        :fixed-header="false"
        :columns="computedColumns"
        :rows="rows"
        :pagination-options="{
          enabled: true,
          mode: 'records',
          dropdownAllowAll: false,
        }"
        @on-column-filter="onColumnFilter"
        v-bind="config"
        :totalRows="totalRows"
        @on-per-page-change="onPageChange($event, true)"
        @on-page-change="onPageChange($event)"
        @on-selected-rows-change="selectionChanged"
      >
        <template #column-filter="{ column, updateFilters }">
          <div
            v-if="
              column.filterOptions &&
              column.filterOptions.enabled &&
              column.config &&
              column.config.filter_type === 'date'
            "
          >
            <DatePicker
              range
              v-model="date_range"
              @input="
                (value) => updateColumnFilters(column, value, updateFilters)
              "
              :key="date_range_key"
              class="w-full"
              format="DD MMM, YYYY"
              value-type="timestamp"
              :placeholder="due_date"
            />
          </div>
          <!-- <dropdown
                    :ref="setFilterRef"
                    reposition
                    searchable
                    class="filter-dropdown"
                    placeholder="Select"
                    v-else-if="column.filterOptions && column.filterOptions.enabled && column.config && !['indicators', 'allocation'].includes(column.config.type)"
                    :options="column.filterOptions.filterDropdownItems"
                    @input="(value) => updateColumnFilters(column, value, updateFilters)"
                    :value="getFilterValue(column)"
                    @search="searchFilter(column, $event)"
                    :limit="1"
                    maxWidth="100%"
                    width="100px"
                    minWidth="100%"
                    :config="{ label: 'name', trackBy: 'id' }"
                    :multiple="false"
                    :taggable="false"
                >
                    <template #caret>
                        <sort-icon class="fill-current" />
                    </template>
                </dropdown> -->
          <div class="" v-else>
            <!-- // adding a blank div to remove the default template   -->
          </div>
        </template>
        <template
          slot="table-row"
          slot-scope="props"
          v-show="props.row.type == 'source' || props.row.isnrnOpen"
        >
          <div
            class="flex gap-3 pl-3"
            v-if="props.column.field === 'credit_source_name'"
            :class="[props.row.system_default && 'default hover-text']"
          >
            <router-link
              :to="{
                name: 'companyDomain',
                params: { nrn_id: props.row.nrn_id },
                query: { credit_source_id: props.row.credit_source_id },
              }"
              class="text-primary whitespace-nowrap cursor-pointer hover:text-dvbrandhoveron"
              >{{ props.row.credit_source_name }}
            </router-link>
          </div>
          <div
            v-if="props.column.field === 'sub_feature_name'"
            class="nrnName"
            :title="props.row.sub_feature_name"
          >
            {{ getNrnName(props.row.sub_feature_name) }}
          </div>
          <div
            v-if="props.column.field === 'output'"
            class="nrnName"
            :title="props.row.output"
          >
            {{ getNrnName(props.row.output) }}
          </div>
          <div
            v-if="props.column.field === 'credits'"
            class="flex justify-evenly"
          >
            <span v-if="!props.row.isCreditPerHitEdit">{{ props.row.credit_per_hit }}</span>
            <input 
              v-else
              type="text" 
              v-model="props.row.credit_per_hit" 
              class="input input-bordered input-xs w-16 text-center" 
              @input="updateValues(props, 'credit_per_hit')"
            />
          </div>
          <div
            v-if="props.column.field === 'service_charge'"
            class="flex justify-evenly"
          >
          <span v-if="!props.row.isServiceChargeEdit">{{ props.row.service_charge_per_hit }}</span>
          <input 
            v-else
            type="text" 
            v-model="props.row.service_charge_per_hit" 
            class="input input-bordered input-xs w-16 text-center" 
            @input="updateValues(props, 'credit_per_hit')"
          />
          </div>
          <div
            v-if="props.column.field == 'tenants'"
            class="flex justify-center"
          >
            <div
              class="indicator cursor-pointer"
              @click="assignedTenants(props.row)"
            >
              <span class="w-2 h-4 indicator-item badge badge-primary">{{
                props.row.tenant_count
              }}</span>
              <font-awesome-icon class="w-6 mx-2" icon="user" />
            </div>
          </div>
          <div
            v-if="props.column.field == 'clients'"
            class="flex justify-center"
          >
            <div
              class="indicator cursor-pointer"
              @click="assignedClients(props.row)"
            >
              <span class="w-2 h-4 indicator-item badge badge-primary">{{
                props.row.client_count
              }}</span>
              <font-awesome-icon class="w-6 mx-2" icon="user" />
            </div>
          </div>
          <div
            v-if="props.column.field == 'actions'"
            class="flex justify-center items-center"
            >  
            <div @click="editSource(props)" class="h-8 w-8 flex items-center justify-center rounded-half duration-150 CreditSourceEditIcon" :class="{'isEditClieked' : props.row.isServiceChargeEdit}">
              <svg data-v-977f11e4="" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="edit" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="cursor-pointer text-sm fill-current svg-inline--fa fa-edit" style="opacity: 1; cursor: pointer;">
                <path data-v-977f11e4="" fill="currentColor" d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z" class="">
                </path>
              </svg>
            </div>
            <button @click="updateSource(props)" v-if="props.row.isCreditPerHitEdit || props.row.isServiceChargeEdit" class="btn normal-case px-4 ml-3 leading-tight flex items-center rounded-xl btn-success btn-xs h-7">
              <span class="capitalize font-bold tracking-wide">save</span></button>
          </div>
        </template>
        <template #loadingContent>
          <div class="w-full py-10 justify-center flex">
            <Loader />
          </div>
        </template>
      </vue-good-table>
      <button
        v-if="isFilters"
        class="cursor-pointer py-1 px-3 bg-gray-100 hover:bg-gray-200 rounded-md text-xs text-black flex justify-center items-center font-semibold absolute z-50 left-2"
        :class="isSelectionChanged ? 'top-14' : 'top-2'"
        @click="clearFilters"
      >
        Clear filters
      </button>
    </div>
    <showTenantClientModal
      ref="assignedListModal"
      :title="currentModalTitle"
      :modalData="modalData"
    />
  </div>
</template>

<script>
import { getSourceList, updateSourceDetails } from "./services";
import SubHeader from "@/components/SubHeader";
import showTenantClientModal from "./tenantClientModal.vue";
import Loader from "@shared/loader";
// import Modal from "@/components/modal-content"
// import dropdown from "@shared/components/dropdown-base";
import { onlyUnique } from "@shared/plugins/utils.js";
import { uuid } from "vue-uuid";
import { DateTime } from "luxon";
import DatePicker from "vue2-datepicker";
// import sortIcon from "@shared/assets/svg/dropdown-double.svg";
import { format, parse } from "date-fns";

export default {
  data: function () {
    return {
      columns: [
        {
          label: "Source",
          field: "credit_source_name",
          config: {
            filter: true,
            alignCenter: true,
            field_type: "action_button",
          },
        },
        {
          label: "Input",
          field: "sub_feature_name",
        },
        {
          label: "Output",
          field: "output",
        },
        {
          label: "Credits/Hits",
          field: "credits",
        },
        {
          label: "Service Charge/Hits",
          field: "service_charge",
        },
        {
          label: "Custom Tenant",
          field: "tenants",
        },
        {
          label: "Custom Client",
          field: "clients",
        },
        {
          label: "Actions",
          field: "actions",
        },
      ],
      params: {
        page: 1,
        limit: 10,
        cache_bypass: true,
      },
      totalRows: 0,
      filters: {},
      serverParams: {},
      searchLoading: {},
      date_range: [],
      filterRefs: [],
      filterOptionsAsync: {},
      filterOptions: {},
      isSelectionChanged: false,
      rows: [],
      loading: false,
      isSourceLoading: false,
      showAssignedModal: false,
      currentModalTitle: "",
      modalData: [],
      allSourceData: [],
    };
  },
  components: {
    SubHeader,
    Loader,
    DatePicker,
    showTenantClientModal,
  },
  async mounted() {
    this.loading = true;
    await this.setSourceList();
    this.filterOptions = this.columnFilterOptions;
    this.loading = false;
  },
  computed: {
    isFilters() {
      return this.serverParams && Object.entries(this.serverParams).length;
    },
    columnFilterOptions() {
      return this.getFilterOptions(this.columnOption, this.rows);
    },
    columnOption() {
      return this.columns.map((el) => ({
        ...el,
        config: {
          ...el.config,
          isLoading: !!this.searchLoading[el.field] || false,
        },
      }));
    },
    computedColumns() {
      // use option list from server if it is:
      const options = this.filterOptions;
      // had to check for empty object
      if (options && Object.entries(options).length) {
        return this.columns.map((col) => {
          const filtered = col.config?.filter ?? true;
          if (col.prefetchedOptions && col.prefetchedOptions.length) {
            return {
              ...col,
              filterOptions: {
                enabled: filtered,
                filterDropdownItems: col.prefetchedOptions,
              },
            };
          } else {
            return {
              ...col,
              filterOptions: {
                enabled: filtered,
                filterDropdownItems: options[col.field],
              },
            };
          }
        });
      } else {
        // TODO remove/rework default filters
        return this.columns.map((col) => {
          const filtered = col.config?.filter ?? true;
          return {
            ...col,
            filterOptions: filtered
              ? {
                  enabled: true,
                  placeholder: "All",
                  filterDropdownItems: this.rows
                    .filter((row) => row[col.field])
                    .map((row) => ({
                      id: row.field_type_id ?? uuid.v4(),
                      name: row[col.field],
                    }))
                    .filter(onlyUnique),
                }
              : undefined,
          };
        });
      }
    },
  },
  methods: {
    async setSourceList() {
      this.isSourceLoading = true;
      const filterParams = this.makeFilterParams();
      let qParams;
      qParams = new URLSearchParams(this.params);
      if (filterParams) {
        qParams = new URLSearchParams({ ...this.params, ...filterParams });
      }
      const { data } = await getSourceList(qParams);
      this.isSourceLoading = true;
      this.rows = data.data;
      this.rows = this.rows?.map(el => {
        el.isCreditPerHitEdit = false,
        el.isServiceChargeEdit = false
        return el;
      })
      this.totalRows = data?.pagination_info.total_count;
    },
    getNrnName(value) {
      if (value?.length > 25) {
        return value.substring(0, 27) + "...";
      } else {
        return value;
      }
    },
    editSource(sourceData) {
      this.rows = this.rows?.map(el => {
        if (el?.nrn_id == sourceData?.row?.nrn_id) {
          el.isCreditPerHitEdit = !sourceData.row.isCreditPerHitEdit
          el.isServiceChargeEdit = !sourceData.row.isServiceChargeEdit
        }
        return el;
      })
    },
    updateValues(sourceData, key) {
      this.rows = this.rows.map(rowData => {
        if(sourceData?.row?.nrn_id == rowData.nrn_id) {
          rowData[key] = sourceData?.row[key]
        }
        return rowData;
      })
    },
    async updateSource(sourceData) {
      let payload ={
        nrn_id: sourceData?.row?.nrn_id,
        credit_per_hit: parseInt(sourceData?.row?.credit_per_hit),
        service_charge_per_hit: parseInt(sourceData?.row?.service_charge_per_hit),
      } 
      if(sourceData?.row?.credit_per_hit != '' && sourceData?.row?.service_charge_per_hit != '') {
        await updateSourceDetails(payload);
        this.$toast.success('Source Update Successfully');
        await this.setSourceList();
      }
      else {
        this.$toast.error("Please fill all required fields");
      }
    },          
    async assignedTenants(data) {
      this.modalData = data.tenants;
      this.currentModalTitle = "Tenants";
      if (this.$refs.assignedListModal.showModal()) {
        this.$refs.assignedListModal.showModal();
      }
    },
    async assignedClients(data) {
      this.modalData = data.clients;
      this.currentModalTitle = "Clients";
      if (this.$refs.assignedListModal.showModal()) {
        this.$refs.assignedListModal.showModal();
      }
    },
    makeFilterParams() {
      let filterParams = {};
      if (this.serverParams) {
        Object.keys(this.serverParams).map((key) => {
          filterParams[key] = this.serverParams[key]
            ? this.serverParams[key]["name"]
            : delete filterParams[key];
          if (this.serverParams[key]["name"] === undefined) {
            delete filterParams[key];
          }
          if (key.includes("date_from") || key.includes("date_to")) {
            filterParams[key] = this.formatDate(filterParams[key]);
          }
        });
      }
      return filterParams;
    },
    async clearFilters() {
      this.serverParams = null;
      await this.setSourceList();
    },
    async onColumnFilter({ columnFilters }) {
      this.params.page = 1;
      this.serverParams = columnFilters ? { ...columnFilters } : null;
      await this.setSourceList();
    },
    selectionChanged(e) {
      // console.log('e :>> ', e);
      if (e) {
        this.isSelectionChanged = e.selectedRows && e.selectedRows.length !== 0;
        this.$emit("on-selected-rows-change", e.selectedRows);
      }
    },
    getFilterOptions(columns, row) {
      return columns.reduce((result, curr) => {
        result[curr.field] = row
          .filter((row) => row[curr.field])
          .map((row) => row[curr.field])
          .filter(onlyUnique)
          .map((el) => ({ id: uuid.v4(), name: el }));
        return result;
      }, {});
    },
    updateColumnFilters(column, value /* , callback */) {
      let filterValObj = {
        ...this.serverParams,
        [column.query_key || column.field]: value
          ? {
              ...value,
              field_type: column.field_type,
            }
          : null,
      };
      if (!filterValObj[column.query_key || column.field]) {
        delete filterValObj[column.query_key || column.field];
      }
      if (
        column.query_key &&
        ["from_due_date", "from_created_at"].includes(column.query_key)
      ) {
        let date = DateTime.fromISO(value.name).toISODate();
        if (date == null) {
          const parsedDate = parse(value.name, "d MMM yyyy", new Date());
          date = format(parsedDate, "yyyy-MM-dd");
          if (filterValObj.from_created_at) {
            filterValObj.from_created_at.name = date
              .toString()
              .substring(10, 0);
          } else {
            filterValObj.from_due_date.name = date.toString().substring(10, 0);
          }
        }
        Object.assign(filterValObj, {
          [`to_${column.field}`]: {
            id: date.ts,
            name: date.toString().substring(10, 0),
            field_type: column.field_type,
          },
        });
      }
      if (
        column.config &&
        /* column.config.query_keys && */ column.config.filter_type === "date"
      ) {
        // uncommment when you want to add customkeys for backend filters
        let [start, end] = value.map((el) => DateTime.fromJSDate(new Date(el)));
        Object.assign(filterValObj, {
          [column.config.query_keys["start"]]: {
            id: start.ts,
            name: value[0] ? start.toString() : null,
            field_type: column.field_type,
          },
        });
        Object.assign(filterValObj, {
          [column.config.query_keys["end"]]: {
            id: end.ts,
            name: value[1] ? end.toString() : null,
            field_type: column.field_type,
          },
        });
      }
      this.onColumnFilter({
        columnFilters: filterValObj,
      });
    },
    async onPageChange(data, resetPage = false) {
      if (resetPage) {
        this.params.page = 1;
      } else this.params.page = data.currentPage;
      this.params.limit = data.currentPerPage;
      await this.setSourceList();
    },
    searchFilter(column, value) {
      this.$emit("search-filter", { column, value });
    },
    setFilterRef(el) {
      if (el) {
        this.filterRefs.push(el);
      }
    },
    getFilterValue(col) {
      // isFilters ? filters[column.field] : null
      if (this.isFilters) {
        return this.serverParams[col.field] ?? this.serverParams[col.query_key];
      } else return null;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@shared/assets/mixins/mixins.scss";
@include tableComponent;

.openNrns {
  display: flex;
  padding: 1px 2px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  cursor: pointer;
}

.CreditSourceEditIcon {
  &:hover {
    background: #407fc8;
    color: #fff;
  }
}

.isEditClieked {
  background: #407fc8;
  color: #fff;
}

.flex-column {
  flex-direction: column;
}

.nrnList {
  padding: 10px;
  border-bottom: 2px solid lightgray;
  border-top: 2px solid lightgray;
  background: white;
}

.nrnName {
  width: max-content;
  word-break: break-word;
}
</style>
