<template>
    <div class="min-h-full relative max-h-full flex flex-col text-base-content">
        <sub-header :backButton="true" class="mb-4" />
        <div class="justify-between w-full flex-grow max-h-full overflow-y-auto rounded-lg self-start bg-white shadow">
            <div v-if="loadingState" class="bg-white p-6 rounded-lg py-24 flex items-center justify-center h-full">
                <Loader />
            </div>
            <div v-else >
                <div class="w-full px-8 py-3">
                    <div class="justify-between flex border-b items-center">
                        <div class="heading ">
                            <h2 class="font-semibold text-md px-1 py-2">Invoice &gt; Record Payment</h2>
                        </div>
                    </div>
                    <div class="">
                        <div class="flex justify-between pt-5 pb-2">
                            <div class="h-auto">
                                <div class="p-5 bg-page-bg rounded-lg ">
                                    <h2 class="font-semibold py-1 capitalize">{{tenantDetails.title}}</h2>
                                    <p class="text-sm capitalize"> <span class="font-semibold">Billing Address : </span> {{address.line_1 + " " 
                                    + address.line_2 + " " + address.city + " " + address.country}} </p>
                                </div>
                            </div>
                            <div class="rounded-lg p-3 bg-page-bg">
                                <div class="px-2 py-1 flex justify-between">
                                    <h2 class="font-semibold pr-4 text-sm">Invoice Number</h2>                           
                                    <p class="text-sm pl-4"> IEN-000302 </p>
                                </div>
                                <div class="px-2 py-1 flex justify-between">
                                    <h2 class="font-semibold pr-4 text-sm">Order Id</h2>                           
                                    <p class="text-sm pl-4"> {{ tenantDetails.order_id }} </p>
                                </div>
                                <div class="px-2 py-1 flex justify-between">
                                    <h2 class="font-semibold pr-4 text-sm">Order Date</h2>                           
                                    <p class="text-sm pl-4"> {{ tenantDetails.created_at }} </p>
                                </div>
                                <div class="px-2 py-1 flex justify-between">
                                    <h2 class="font-semibold pr-4 text-sm">Terms</h2>                           
                                    <p class="text-sm pl-4"> {{ term }} </p>
                                </div>
                                <div class="px-2 py-1 flex justify-between">
                                    <h2 class="font-semibold pr-4 text-sm">Due Date</h2>                           
                                    <p class="text-sm pl-4"> {{ tenantDetails.payment_due_date }} </p>
                                </div>
                            </div>
                        </div>
                        <div class="pb-2 border rounded-lg py-2 px-3">
                            <h2 class="font-semibold text-md py-2 border-b">Payment Details</h2>
                            <FormulateForm #default="{ hasErrors }">
                                <div class="grid grid-cols-3 gap-x-6 pt-1">
                                    <FormulateInput disabled class="w-full capitalize" label="Tenant Name" placeholder="Tenant Name" type="text"
                                        :element-class=" (context, classes) => ['flex-1 min-w-full'].concat(classes)" v-model="tenantDetails.title"
                                    />
                                    <FormulateInput class="w-full" label="Payment" placeholder="Payment" type="text"
                                        :element-class=" (context, classes) => ['flex-1 min-w-full'].concat(classes)"
                                    />
                                    <FormulateInput class="w-full" label="Amount Recieved" placeholder="Amount" type="text"
                                        :element-class=" (context, classes) => ['flex-1 min-w-full'].concat(classes)" v-model="amountReceived"
                                        :validation="checkAmountValidation" :validation-messages="{ matches: 'Amount must be a numeric value'}"
                                    /> 
                                </div>
                                <div class="grid grid-cols-3 gap-x-6 pt-1">
                                    <FormulateInput  class="w-full" label="Onboarding date" placeholder="Onboarding date" type="date" 
                                        :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" v-model="paymentDate" 
                                    />
                                    
                                    <div class="h-6">
                                        <h2 class="mb-1 mt-1">Payment Mode</h2>
                                        <Dropdown  placeholder="Cash" :options="formatOptions" searchable :value="formatValue"
                                            :colors="{
                                                text: '#282828',
                                                bg: 'white',
                                                svg: '#282828',
                                                border: '#bcbcbc',
                                            }"
                                            @input="(value) => updateFormatValue(value)" :config="{ label: 'format', trackBy: 'format' }"
                                            maxWidth="100%"  minWidth="100%" minHeight="20%" :disabled="baseCurrency" v-model="paymentMode"
                                        />
                                    </div>
                                    <FormulateInput class="w-full" label="Payment Reference" placeholder="Payment Reference" type="text"
                                        :element-class=" (context, classes) => ['flex-1 min-w-full'].concat(classes)" v-model="paymentReference"
                                    /> 
                                </div>
                                <div class="grid grid-cols-3 gap-x-6 pt-1">
                                    <FormulateInput  class="w-full" label="Notes" placeholder="Notes" type="text" 
                                        :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" v-model="notes" 
                                    />
                                </div>
                                <div class="flex justify-end max-w-full">
                                    <Button
                                        @click="setInvoiceRecord(hasErrors)"
                                        class="btn-primary float-right mt-4"
                                        text="Save"
                                        :disabled="baseCurrency"
                                    />
                                </div>
                            </FormulateForm>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getInvoiceRecord, getInvoiceList, getTenantAddress, getInvoiceDetails } from "./services"
import SubHeader from "@/components/SubHeader";
import Button from "@/components/button";
import Dropdown from "@shared/components/dropdown-base";
import Loader from "@shared/loader";
// End
export default {
        name: "recordPayment",      
        components: {
            SubHeader,
            Button,
            // Input,
            Dropdown,
            Loader
        },
        data: function () {
            return {
                formatOptions: [
                    { format: "Cash" },
                    { format: "Check" },
                    { format: "Credit/Debit Cards" },
                    { format: "Mobile Payment" },
                ],
                rowsData:'',
                amountReceived:'',
                paymentMode:'',
                paymentReference:'',
                paymentDate:'',
                notes:'',
                address:'',
                term:'',
                tenantDetails:{},
                loadingState: false,
            }  
        },
        async mounted() {
            this.loadingState = true;
            await this.setInvoiceList();
            await this.setInvoiceSubList();
            this.loadingState = false;
           
        },
        computed: {
            checkAmountValidation(){
                return this.amountReceived ? 'matches:/^[0-9]*$/' : ''
            }
        },
        methods:{            
            async setInvoiceRecord()
            {
                let payload ={
                    amount_received: parseInt(this.amountReceived),
                    payment_mode: this.paymentMode.format,
                    payment_reference: this.paymentReference,
                    notes: this.notes,
                    payment_date: this.paymentDate
                }
                if(payload.amount_received != '' && payload.payment_mode != '' && payload.payment_reference != '' && payload.payment_date != '' && payload.notes != '')
                {
                    const { data } = await getInvoiceRecord(this.$route.params.id, payload);
                    this.rowsData= data; 
                    if(this.rowsData == true)
                    {
                        this.$toast.success("successfully")
                    }
                    else{
                        this.$toast.error("Failed")
                    }
                }
                else{
                    this.$toast.error("Fields Should not blank")
                }
            },
            async setInvoiceList(){
                const { data } = await getInvoiceList()
                const invoiceData = data.data.filter((resp)=> resp.order_id == this.$route.params.id);
                let { title, tenant_id, order_id, payment_due_date, created_at } = invoiceData[0];
                this.tenantDetails = {
                    title,
                    tenant_id, 
                    order_id, 
                    payment_due_date, 
                    created_at
                }
                if(this.tenantDetails.tenant_id != ''){
                    this.setBillingAddress();
                }
                else{
                    this.$toast.error("Something Went Wrong")
                }
                
            },
            async setBillingAddress(){
                const { data } = await getTenantAddress(this.tenantDetails.tenant_id)
                this.address = data.address.billing_address;
            },
            async setInvoiceSubList()
            {
                const { data } = await getInvoiceDetails(this.$route.params.id)
                this.subData = data.data
                this.term = this.subData[0].payment_term
                
            },
        },         
    };
</script>
