
import axios from "@/axios"

export async function getSourceList(params) {
    const url = `/nrn?${params}`
    return axios.get(url)
}

export async function updateSourceDetails(payload) {
    const url = `/nrn`
    return axios.put(url, payload)
}
export async function getSourceDetails(id ) {
    const url = `/credit-source/${id}`
    return axios.get(url)
}
export async function getTenantDetails() {
    const url = `/tenant/tenants/clients`
    return axios.get(url)
}
export async function getAssignTenanList(id) {
    const url = `/credit-source/${id}/tenants`
    return axios.get(url)
}
export async function assignTenant(payload) {
    const url = `/nrn/tenants`
    return axios.post(url, payload)
}
export async function assignClient(payload) {
    const url = `/nrn/clients`
    return axios.post(url, payload)
}